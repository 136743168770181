import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
    useGetOrderByIdQuery, 
    useAddMaterialToOrderMutation,
    useAddPaymentToOrderMutation, 
    useDeleteStageFromOrderMutation,
    useDeletePaymentFromOrderMutation,
    useDeleteMaterialFromOrderMutation,
    useUpdateOrderMutation
  } from '../../redux/order/orderApi';
  import { useGetAllMaterialsQuery } from '../../redux/list/material/materialApi';
  import { useGetAllCustomersQuery } from '../../redux/list/customer/customerApi';
  import '../css/OrderList.css';
  import { useOrderHelpers } from '../../hooks/useOrderHelpers';

const Update = () => {
    const { _id } = useParams(); 
    const { data: order, error, isLoading } = useGetOrderByIdQuery(_id);
    const { data: materials = [] } = useGetAllMaterialsQuery();
    const { data: customers = [] } = useGetAllCustomersQuery();
    const [newPayment, setNewPayment] = useState({ amount: '', method: 'акчалай' });
    const [addMaterialToOrder] = useAddMaterialToOrderMutation();
    const [addPaymentToOrder] = useAddPaymentToOrderMutation(); 
    const [deletePaymentFromOrder] = useDeletePaymentFromOrderMutation();
    const [deleteMaterialFromOrder] = useDeleteMaterialFromOrderMutation();
    const [deleteStageFromOrder] = useDeleteStageFromOrderMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');

    const [isLoadinggg, setIsLoadinggg] = useState(false);
    const [whatsapp, setWhatsapp] = useState(0);
    const [paymentForDesign,setPaymentForDesign] = useState(0);
    const [leuvers,setLeuvers] = useState(0);
    const [frames,setFrames] = useState(0);
    const [price,setPrice] = useState(0);
    const [orderDetails,setOrderDetails] = useState('');
    const [priority, setPriority] = useState('');
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [rate, setRate] = useState(0);
    const [installation, setInstallation] = useState(0);
    const [customer, setCustomer] = useState('');
    const [users, setUsers] = useState([]);
    const [currentStage,setCurrentStage] = useState('');
    const { handleUpdateOrderCurrentStageProperty } = useOrderHelpers();
    const allStages = ['Дизайн', 'Печать', 'Постпечать', 'Установка', 'Финиш'];

    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const response = await fetch('https://api.ilim-ordo.kg/api/users');
          if (response.ok) {
            const usersData = await response.json();
            setUsers(usersData);
          } else {
            console.error('Failed to fetch users');
          }
        } catch (err) {
          console.error('Error fetching users:', err);
        }
      };
  
      fetchUsers();
      if(order){
        setPriority(order.info.priority);
        setHeight(order.info.dimensions.height);
        setWidth(order.info.dimensions.width);
      }
    }, [order]);
    useEffect(() => {
      if (order?.currentStage) {
          const roles = {
              "Старт": "Дизайн",
              "Дизайн": "Печать",
              "Печать": "Постпечать",
              "Постпечать": "Установка",
              "Установка": "Финиш",
              "Финиш": "Старт"
          };
          console.log('order?.currentStage order.currentStage: ', order.currentStage);
          console.log('order?.currentStage roles[order.currentStage]: ', roles[order.currentStage]);
          setCurrentStage(roles[order.currentStage]  || '');
          // setOrderCurrentStage(order?.currentStage);
      }
  }, [order?.currentStage]); 
    const [newStage, setNewStage] = useState({
      stageName: '',
      assignedUsers: [],
      startedAt: '',
      // completedAt: '',
    });
    if (isLoading) return <p>Жүктөлүп жатат...</p>;
    if (error) return <p>Ката чыкты</p>;
    // Filter out existing stage names from `order.stages`
    const availableStages = allStages.filter(stageName =>
      !order.stages.some(stage => stage.stageName === stageName)
    );
    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setNewPayment({ ...newPayment, [name]: value });
    };
    const handleAddPayment = async (orderId) => {
      setIsLoadinggg(true);
      try {
          await addPaymentToOrder({ orderId, ...newPayment });
          setNewPayment({ amount: '', method: 'cash' }); 
          window.location.reload();
      } catch (error) {
          console.error("Error adding payment:", error);
      }
    };

    const handlePaymentDelete = async (orderId, paymentId) => {
      setIsLoadinggg(true);
        try {
            await deletePaymentFromOrder({ orderId, paymentId });
            window.location.reload();
        } catch (error) {
            console.error("Error deleting payment:", error);
        }
    };

    const handleMaterialChange = (e) => {
      setSelectedMaterial(e.target.value);
    };

    const handleAddMaterial = async (orderId) => {
        setIsLoadinggg(true);
        if (selectedMaterial) {
            await addMaterialToOrder({ orderId, materialId: selectedMaterial });
            setSelectedMaterial(''); // Reset the selection
            window.location.reload();
        }
    };

    const handleMaterialDelete = async (orderId, materialId) => {
      setIsLoadinggg(true);
      try {
          await deleteMaterialFromOrder({ orderId, materialId });
          window.location.reload();
      } catch (error) {
          console.error("Error deleting Material:", error);
      }
    };

    const handleAddCustomer =  (orderId) => {
        setIsLoadinggg(true);
        if (selectedCustomer) {
            handleUpdateOrderProperty(orderId,'customer',selectedCustomer)
            setSelectedCustomer(''); // Reset the selection
        }else{
          handleUpdateOrderProperty(orderId,'customer',customer)
          setCustomer('');
        }
    };

    // Handler to update a single property
    const handleUpdateOrderProperty = async (orderId, property, value) => {
      setIsLoadinggg(true);
      try {
        await updateOrder({ id: orderId, [property]: value });
      } catch (error) {
        console.error("Error deleting Material:", error);
      }
      window.location.reload();
    };

    const handleStageChange = (e) => {
      const { name, value } = e.target;
      setNewStage({ ...newStage, [name]: value });
    };
  
    const handleAssignedUsersChange = (e) => {
      const selectedOptions = Array.from(e.target.selectedOptions);
      const selectedUserIds = selectedOptions.map((option) => option.value);
      setNewStage({ ...newStage, assignedUsers: selectedUserIds });
    };
  
    const handleAddStage = async (orderId) => {
      setIsLoadinggg(true);
      console.log('newStage: ',newStage)
      try {
        await updateOrder({
          id: orderId,
          stages: [{ ...newStage }],
        });
        window.location.reload();
      } catch (error) {
        console.error('Error adding stage:', error);
      } finally {
        setIsLoadinggg(false);
      }
    };
  //   const handleDeleteStageIdInOrder = async (orderId, stageId) => {
  //     try {
  //         const response = await axios.delete(`https://api.ilim-ordo.kg/api/orders/${orderId}/stages/${stageId}`);
  //         console.log('Stage removed successfully:', response.data);
  //         // Optionally, refetch or update the UI with the new data
  //     } catch (error) {
  //         console.error('Error removing stage:', error);
  //     }
  // };
  const handleStageDelete = async (orderId, stageId) => {
    setIsLoadinggg(true);
      try {
          await deleteStageFromOrder({ orderId, stageId });
          window.location.reload();
      } catch (error) {
          console.error("Error deleting stage:", error);
      }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};
const handleCompleted = async (order,userId)=>{
  // e.preventDefault();
  try{
      // console.log('user: ',user,'temp currentStage: ',currentStage,'order.currentStage: ',order.currentStage)
      await Promise.all([
          handleUpdateOrderCurrentStageProperty(order._id, 'currentStage', order.currentStage, userId, order),
          handleUpdateOrderProperty(order._id, 'currentStage', currentStage)
      ]);
  } catch (error) {
      console.error('Error completing stage:', error);
  }
};
    return (
        <div>
              <div className="order-header">
                <strong>#</strong> {order.orderNo}
                <strong>Азыркы этап:</strong> {order.currentStage}
                <strong >Кабыл алган:</strong> {order.createdBy.username}
                <strong>Датасы:</strong> {formatDate(order.createdAt)}
                <strong>Баасы:</strong> {order.info.price} сом
                <strong>Төлөндү:</strong> {order.info.payments.reduce((sum, payment) => sum + payment.amount, 0)} сом
                <strong>Карызы:</strong> {order.info.price - (order.info.payments.reduce((sum, payment) => sum + payment.amount, 0))}  сом
              </div>
              <div className="order-details">
                <div className="section-container">
                  <h2>Клиент</h2> 
                  <div className="order-info">{order.customer}</div>
                  {/* Dropdown to Add Customer */}
                  <div className="vertical-end">
                      <h4>Клиент кошуу</h4>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <select 
                          value={selectedCustomer} 
                          onChange={(e)=>setSelectedCustomer(e.target.value)}                          
                        >
                            <option value="">Клиент тандаңыз</option>
                            {customers.map(customer => (
                                <option key={customer._id} value={customer.title}>
                                    {customer.title}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            name="customer"
                            placeholder="Заказчик"
                            value={customer}
                            onChange={(e)=>setCustomer(e.target.value)}
                            required                          
                        />
                        <button onClick={() => handleAddCustomer(order._id)} disabled={isLoadinggg}>Кош</button>
                      </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Төлөмдөр</h2> 
                  <ul className="order-info">
                      {order.info.payments.map(payment => (
                          <li key={payment._id} className="list-item">
                              <span>{`${payment.amount} сом`}</span>
                              <span>{`(${payment.method})`}</span>
                              <button                                    
                                  onClick={() => handlePaymentDelete(order._id, payment._id)}
                                  disabled={isLoadinggg}
                              >
                                  Өчүрүү
                              </button>
                          </li>
                      ))}
                  </ul>
                  {/* Form to Add New Payment */}
                  <div className="vertical-end">
                      <h4>Төлөм кошуу</h4>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <input
                            type="number"
                            name="amount"
                            placeholder="Төлөм суммасы"
                            value={newPayment.amount}
                            onChange={handlePaymentChange}
                            required                          
                        />
                        <select
                            name="method"
                            value={newPayment.method}
                            onChange={handlePaymentChange}
                            required                          
                        >
                            <option value="акчалай">акчалай</option>
                            <option value="мбанк">мбанк</option>
                            <option value="перечис.">перечис.</option>
                        </select>
                        <button onClick={() => handleAddPayment(order._id)} disabled={isLoadinggg}>Кош</button>
                      </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Материалдар</h2> 
                  <ul>
                      {order.info.materials.map(material => (
                          <li key={material._id} className="list-item">
                              <span>{material.title}</span>
                              <button 
                                  className="list-item"
                                  onClick={() => handleMaterialDelete(order._id, material._id)}
                                  disabled={isLoadinggg}
                              >
                                  Өчүрүү
                              </button>
                          </li>
                      ))}
                  </ul>
                  {/* Dropdown to Add Material */}
                  <div className="vertical-end">
                      <h4>Материал кошуу</h4>
                      <select 
                        value={selectedMaterial} 
                        onChange={handleMaterialChange}  
                        className="select-margin"
                      >
                          <option value="">Материал тандаңыз</option>
                          {materials.map(material => (
                              <option key={material._id} value={material._id}>
                                  {material.title}
                              </option>
                          ))}
                      </select>
                      <button onClick={() => handleAddMaterial(order._id)} disabled={isLoadinggg}>Кош</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Whatsapp</h2> 
                  <div className="order-info">{order.info.whatsapp}</div>
                  <div className="vertical-end">
                      <h4>Номерди өзгөртүү</h4>
                      <input
                          type="number"
                          name="whatsapp"
                          placeholder="Whatsapp"
                          value={whatsapp}
                          onChange={(e)=>setWhatsapp(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.whatsapp',whatsapp)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Дизайнерге</h2> 
                  <div className="order-info">{order.info.paymentForDesign} сом</div>
                  <div className="vertical-end">
                      <h4>Дизайнердин акысын өзгөртүү</h4>
                      <input
                          type="number"
                          name="paymentForDesign"
                          placeholder="Дизайнердин акысы"
                          value={paymentForDesign}
                          onChange={(e)=>setPaymentForDesign(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.paymentForDesign',paymentForDesign)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Люверси</h2> 
                  <div className="order-info">{order.info.leuvers} сом</div>
                  <div className="vertical-end">
                      <h4>Люверсинин баасын өзгөртүү</h4>
                      <input
                          type="number"
                          name="leuvers"
                          placeholder="leuvers"
                          value={leuvers}
                          onChange={(e)=>setLeuvers(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.leuvers',leuvers)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Рейка</h2> 
                  <div className="order-info">{order.info.frames} сом</div>
                  <div className="vertical-end">
                      <h4>Рейканын баасын өзгөртүү</h4>
                      <input
                          type="number"
                          name="frames"
                          placeholder="frames"
                          value={frames}
                          onChange={(e)=>setFrames(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.frames',frames)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Баасы</h2> 
                  <div className="order-info">{order.info.price} сом</div>
                  <div className="vertical-end">
                      <h4>Заказдын баасын өзгөртүү</h4>
                      <input
                          type="number"
                          name="price"
                          placeholder="price"
                          value={price}
                          onChange={(e)=>setPrice(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.price',price)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Маалымат</h2> 
                  <div className="order-info">{order.orderDetails}</div>
                  <div className="vertical-end">
                      <h4>Заказ тууралуу маалыматты өзгөртүү</h4>
                      <input
                          type="text"
                          name="orderDetails"
                          placeholder="Заказ тууралуу маалымат"
                          value={orderDetails}
                          onChange={(e)=>setOrderDetails(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'orderDetails',orderDetails)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Приоритет</h2>
                  <div className="order-info">{order.info.priority}</div>
                  <div className="vertical-end">
                    <h4>Приоритетти өзгөртүү</h4>
                    <select
                      name="priority"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      required
                    >
                      <option value="Обычный">Обычный</option>
                      <option value="Срочный">Срочный</option>
                    </select>
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.priority', priority)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Өлчөмү</h2>
                  <div className="order-info">
                    Бийиктиги: {(order.info.dimensions.height * 0.01).toFixed(2)} м, Туурасы: {(order.info.dimensions.width * 0.01).toFixed(2)} м, 
                     &nbsp;
                    {(order.info.dimensions.height * order.info.dimensions.width) * 0.0001} м2
                  </div>
                  <div className="vertical-end">
                    <h4>Өлчөмүн өзгөртүү</h4>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <div>
                        <label htmlFor="height">Бийиктиги (cм):</label>
                        <input
                          type="number"
                          id="height"
                          name="height"
                          placeholder="Бийиктиги (cм)"
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                          step="1"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="width">Туурасы (cм):</label>
                        <input
                          type="number"
                          id="width"
                          name="width"
                          placeholder="Туурасы (cм)"
                          value={width}
                          onChange={(e) => setWidth(e.target.value)}
                          step="1"
                          required
                        />
                      </div>
                      <button 
                        onClick={() =>
                          handleUpdateOrderProperty(order._id, 'info.dimensions', { height, width, size: (height * width * 0.0001) })
                        } 
                        disabled={isLoadinggg} 
                        style={{ alignSelf: 'flex-end' }}>
                        Өзгөрт
                      </button>
                    </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Ставка</h2>
                  <div className="order-info">{order.info.rate} сом</div>
                  <div className="vertical-end">
                    <h4>Ставканы өзгөртүү</h4>
                    <input
                      type="number"
                      name="rate"
                      placeholder="Ставка"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      required
                    />
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.rate', rate)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Установка</h2>
                  <div className="order-info">{order.info.installation} сом</div>
                  <div className="vertical-end">
                    <h4>Установканын баасын өзгөртүү</h4>
                    <input
                      type="number"
                      name="installation"
                      placeholder="Установка"
                      value={installation}
                      onChange={(e) => setInstallation(e.target.value)}
                      required
                    />
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.installation', installation)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
              </div>

              <h3>Этаптары</h3>
              {order.stages.map((stage, index) => (
                <div key={index} className="stages-container">
                  <div className="stage-detail stage-detail-width-90">
                    <strong>Этап:</strong><br></br> 
                    {stage.stageName}
                  </div>
                  <div className="stage-detail stage-detail-width">
                    <strong>Аткаруучулар:</strong><br></br>  
                    {stage.assignedUsers.map(user => user.username).join(', ')}
                  </div>
                  <div className="stage-detail stage-detail-width-90">
                    <strong>Башталды:</strong><br></br>  
                    {stage.startedAt ? formatDate(stage.startedAt) /*new Date(stage.startedAt).toLocaleDateString()*/ : 'Баштала элек'}
                  </div>
                  <div className="stage-detail stage-detail-width-90">
                    <strong>Аяктады:</strong><br></br>  
                    {stage.completedAt ? formatDate(stage.completedAt)/*new Date(stage.completedAt).toLocaleDateString()*/ : 'Бүтө элек'}
                  </div>
                  <button className='button-height' onClick={() => handleStageDelete(order._id,stage._id)} disabled={isLoadinggg}>Өчүр</button>
                  {!stage.completedAt && (
                  <button className="button-margin button-height" onClick={() => handleCompleted(order,stage.assignedUsers[0]._id)} disabled={isLoadinggg}>Аткарылды</button>
                  )}
                  
                </div>
              ))}
                          {/* Form to add a new stage */}





              <div className="stage-form">
                <h4>Жаңы этап кошуу</h4>
                <div className="form-row">
                  <div className="form-group">
                    <label>Этаптын аталышы:</label>
                    <select name="stageName" value={newStage.stageName} onChange={handleStageChange}>
                      <option value="">Этап тандаңыз</option>
                      {availableStages.map((stageName, index) => (
                          <option key={index} value={stageName}>
                              {stageName}
                          </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Аткаруучулар:</label>
                    <select
                      name="assignedUsers"
                      multiple
                      value={newStage.assignedUsers}
                      onChange={handleAssignedUsersChange}
                    >
                      {/* Replace this with a mapped list of users */}
                      {users.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Башталды:</label>
                    <input
                      type="date"
                      name="startedAt"
                      value={newStage.startedAt}
                      onChange={handleStageChange}
                    />
                  </div>

                  {/* <div className="form-group">
                    <label>Аяктады:</label>
                    <input
                      type="date"
                      name="completedAt"
                      value={newStage.completedAt}
                      onChange={handleStageChange}
                    />
                  </div> */}

                  <div className="form-group">
                    <button
                      onClick={() => handleAddStage(order._id)}
                      disabled={isLoadinggg || !newStage.stageName}
                      className="add-stage-button"
                    >
                      {isLoadinggg ? 'Кошуп жатат...' : 'Этап кошуу'}
                    </button>
                  </div>
                </div>
              </div>
        </div>
        );
}

export default Update;