import React, { useState } from 'react';
import OrderStage from '../order/OrderStage';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const IlimOrdo = () => {
    const { user } = useSelector((state) => state.auth);
    const hasManagerRole = user?.roles?.includes("ROLE_МЕНЕДЖЕР");
    const hasDesignerRole = user?.roles?.includes("ROLE_ДИЗАЙНЕР");
    const hasPrinterRole = user?.roles?.includes("ROLE_ПЕЧАТНИК");
    const hasPostprinterRole = user?.roles?.includes("ROLE_ПОСТПЕЧАТНИК");
    const hasInstallatorRole = user?.roles?.includes("ROLE_УСТАНОВЩИК");
    // const [selectedStage, setSelectedStage] = useState(null);
    // // Define role-to-stage mapping
    // const stageMapping = {
    //     "ROLE_ДИЗАЙНЕР": "Старт", // Manager role - can view all orders
    //     "ROLE_ПЕЧАТНИК": "Дизайн",
    //     "ROLE_ПОСТПЕЧАТНИК": "Печать",
    //     "ROLE_УСТАНОВЩИК": "Постпечать"
    // };
    // // Filter roles that the user has
    // const userRoles = user?.roles || [];
    // const availableStages = userRoles
    //     .filter(role => stageMapping[role]) // Keep only roles that have a corresponding stage
    //     .map(role => ({ role, stageName: stageMapping[role] }));

    return (
        <div>
            <h1>Пользователь</h1>
            <div>
                {hasDesignerRole ? <><Link to={'/orderStage/Дизайн'}>Дизайнер</Link><br></br></> :''}
                {hasPrinterRole ? <><Link to={'/orderStage/Печать'}>Печатник</Link><br></br></> :''}
                {hasPostprinterRole ? <><Link to={'/orderStage/Постпечать'}>Постпечатник</Link><br></br></> :''}
                {hasInstallatorRole ? <><Link to={'/orderStage/Установка'}>Установщик</Link><br></br></> :''}
                {hasManagerRole ? <Link to={'/orders'}>Менеджер</Link> :''}
                {/* {hasDesignerRole ? <Link to={'orderStage/Старт'}></Link> :''} */}



                {/* {availableStages.map(({ role, stageName }) => (
                    <button
                        key={role}
                        onClick={() => setSelectedStage(stageName)}
                    >
                        {stageName}
                    </button>
                ))} */}
            </div>
            {/* <div>
                {selectedStage ? (
                    <OrderStage stageName={selectedStage} />
                ) : (
                    <p>Please select a stage to view orders.</p>
                )}
            </div> */}
        </div>
    );
};

export default IlimOrdo;
