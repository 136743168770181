// /src/components/order/Orders.jsx
import React, { useEffect, useState } from 'react';
import './../css/table.css';
import './../css/header.css';
import './../auth/auth.css';
import { Link } from 'react-router-dom';
import { useDeleteOrderMutation } from '../../redux/order/orderApi';
import { useSelector } from 'react-redux';

const Orders = () => {
    
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteOrder] = useDeleteOrderMutation();
    const { user } = useSelector((state) => state.auth);
    const hasManagerRole = user?.roles?.includes("ROLE_МЕНЕДЖЕР");
    const hasDesignerRole = user?.roles?.includes("ROLE_ДИЗАЙНЕР");
    const hasPrinterRole = user?.roles?.includes("ROLE_ПЕЧАТНИК");
    const hasPostprinterRole = user?.roles?.includes("ROLE_ПОСТПЕЧАТНИК");
    const hasInstallatorRole = user?.roles?.includes("ROLE_УСТАНОВЩИК");
    // Fetch orders from the API
    useEffect(() => {
        const fetchOrders = async () => {
            const roleStageMapping = {
                hasDesignerRole: "Старт",
                hasPrinterRole: "Дизайн",
                hasPostprinterRole: "Печать",
                hasInstallatorRole: "Постпечать",
            };

            try {
                let response;
                if (hasManagerRole) {
                    response = await fetch('https://api.ilim-ordo.kg/api/order?status=true');
                } else {
                    const stageName = Object.keys(roleStageMapping).find(
                        (role) => eval(role) // Dynamically check roles
                    );
                    if (stageName) {
                        response = await fetch(`https://api.ilim-ordo.kg/api/order?status=true&stageName=${roleStageMapping[stageName]}`);
                    }
                }

                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }

                const data = await response.json();
                console.log('data: ', data);
                setOrders(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        console.log('useEffect triggered');
        fetchOrders();
    }, [hasManagerRole, hasDesignerRole, hasPrinterRole, hasPostprinterRole, hasInstallatorRole]);

    if (loading) {
        return <div className="auth-container">Заказдар жүктөлүп жатат...</div>;
    }

    if (error) {
        return <div className="auth-container">Ката: {error}</div>;
    }
    const handleDelete = async (id) => {
        try {
            await deleteOrder(id).unwrap();
            //   console.log(`Order with id ${id} deleted successfully.`);
            window.location.reload();
        } catch (error) {
          console.error('Failed to delete the order:', error);
        }
      };
      const isLastStageCompleted = (order) => {
        if (order.stages && order.stages.length > 0) {
          return (
            order.stages[order.stages.length - 1].stageName === order.currentStage &&
            order.stages[order.stages.length - 1].completedAt
          );
        }
        return false; // Return false if stages array is empty or not present
      };
    //   const isLastStageHasStarted = (order) => {
    //     if (order.stages && order.stages.length > 0) {
    //       return (
    //         order.stages[order.stages.length - 1].stageName === order.currentStage &&
    //         order.stages[order.stages.length - 1].startedAt
    //       );
    //     }
    //     return false; // Return false if stages array is empty or not present
    //   };
    //   orders.forEach((order) => {
    //     console.log(`Order No: ${order.orderNo}`);
                // order.stages.forEach((stage) => {
                // console.log(`  Stage: ${stage.stageName}`);
                // stage.assignedUsers.forEach((user) => {
                //     console.log(`    Assigned User: ${user.username}`);
                // });
                // });
    //   });
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    return (
        <div>
            <div className="header">
                <h2>Заказдар</h2>
                {hasManagerRole ? (
                    <>
                    <Link to={'/customer'} className="create-btn blue-color">
                        Клиенттер
                    </Link>

                    <Link to={'/material'} className="create-btn blue-color">
                        Материалдар
                    </Link>

                    <Link to={'/user'} className="create-btn blue-color">
                        Колдонуучулар
                    </Link>

                    <Link to={'/createOrder'} className="create-btn">
                        Жаңы заказ 
                    </Link>
                </>
                ) : (
                    <></>
                )}
            </div>
            {orders.length === 0 ? (
                <p>Заказдар жүктөлө элек.</p>
            ) : (
                <table className="auth-form">
                    <thead>
                        <tr>
                            <th>Номери</th>
                            <th></th>
                            <th>Төлөмдөр</th>
                            <th>Клиент</th>
                            <th>Стадия</th>
                            <th>Материалдар</th>
                            <th>Размер</th>
                            <th></th>
                            <th></th>
                            <th>Ким бекитти</th>
                            <th>Датасы</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order,index) => (
                            <tr key={order._id || index}>
                                <td>
                                    <Link to={'/designer/' + order._id} className="submit-button">                                    
                                        {order.orderNo}
                                    </Link>
                                </td>
                                <td style={{ color: order.info.priority === 'Срочный' ? 'red' : 'inherit' }}>
                                    Аталышы: {order.title}<br></br>
                                    Маалымат: {order.details}
                                </td>
                                <td>
                                    {order.info.payments
                                        ? order.info.payments.map((payment, index) => (
                                              <div key={payment.id || index}>
                                                  {payment.amount} сом ({payment.method})
                                              </div>
                                          ))
                                        : 'Төлөм жок'}
                                </td>
                                <td>{order.customer}<br></br>
                                    <a
                                        href={`https://wa.me/${order.info.whatsapp}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="whatsapp-link"
                                    >
                                        {order.info.whatsapp}
                                    </a>
                                </td>
                                <td >
                                    {order.currentStage}<br></br>
                                    <div style={{marginLeft:'20px',fontStyle:'italic',fontSize:'12px'}}>{isLastStageCompleted(order) ? 'Аткарылды' : !isLastStageCompleted(order) ? 'Бүтө элек' : 'Баштала элек'}</div>
                                    <ul>
                                        {order.stages.slice().reverse().map((stage) => (
                                        <li key={stage._id}>
                                            {stage.stageName} &nbsp;
                                            (&nbsp;
                                                {stage.assignedUsers.slice().reverse().map(user => user.username).join(', ')}
                                            &nbsp;)
                                        </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    {order.info.materials.map((material,index) => (                                        
                                            <div key={material.id || index}>
                                                {material.title} 
                                            </div>
                                        ))
                                    }
                                </td>
                                <td>{order.info.dimensions.height} см x {order.info.dimensions.width} см = {order.info.dimensions.size} м2</td>
                                {hasManagerRole ? (
                                    <>
                                    <td>Ставка: {order.info.rate} сом<br></br>
                                        Баасы: {order.info.price} сом
                                    </td>
                                    <td>Печатник: {order.info.paymentForPrinter} сом<br></br>
                                        Диз. акысы: {order.info.paymentForDesign} сом<br></br>
                                        Рейка: {order.info.frames} сом <br></br>
                                        Люверси: {order.info.leuvers} сом <br></br>
                                        Установка: {order.info.paymentForInstallation} сом 
                                    </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <td>{order.createdBy?.username || 'Белгисиз'}</td>
                                {/* <td>{new Date(order.createdAt).toLocaleDateString()}</td> */}
                                <td>{formatDate(order.createdAt)}</td>
                                {hasManagerRole ? (
                                <td >
                                    <Link to={'/update/' + order._id} className="submit-button">                                    
                                        Өзгөртүү
                                    </Link>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => handleDelete(order._id)}>Өчүрүү</button>
                                </td>
                                ) : (
                                    <></>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Orders;
