import {useState,useEffect} from 'react';
import './../../css/manager.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetOrderByIdQuery } from '../../../redux/order/orderApi';
import { useOrderHelpers } from '../../../hooks/useOrderHelpers';
import { useSelector } from 'react-redux';

const Designer = () => {
    const { _id } = useParams(); 
    const { user } = useSelector((state) => state.auth);
    
    const navigate = useNavigate();
    const { data: order, error, isLoading } = useGetOrderByIdQuery(_id);
    const [paymentForDesign,setPaymentForDesign] = useState(0);
    const [paymentForPrinter,setPaymentForPrinter] = useState(0);
    const [paymentForPostprinter,setPaymentForPostprinter] = useState(0);
    const [paymentForInstallation,setPaymentForInstallation] = useState(0);
    const [currentStage,setCurrentStage] = useState('');
    const [orderCurrentStage,setOrderCurrentStage] = useState('');
    const [frames, setFrames] = useState(0);
    const [leuvers, setLeuvers] = useState(0);
    // const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        if (order?.info?.paymentForDesign) {
            setPaymentForDesign(order.info.paymentForDesign);
        }
    }, [order?.info?.paymentForDesign]);
    useEffect(() => {
        if (order?.info?.paymentForPrinter) {
            setPaymentForPrinter(order.info.paymentForPrinter);
        }
    }, [order?.info?.paymentForPrinter]);
    useEffect(() => {
        if (order?.info?.frames) {
            setFrames(order.info.frames);
        }
        if (order?.info?.leuvers) {
            setLeuvers(order.info.leuvers);
        }
    }, [order?.info?.frames,order?.info?.leuvers]);
    useEffect(() => {
        if (order?.info?.paymentForInstallation) {
            setPaymentForInstallation(order.info.paymentForInstallation);
        }
    }, [order?.info?.paymentForInstallation]);
    // useEffect(() => {
    //     console.log('Component mounted: Designer');
    //     return () => console.log('Component unmounted: Designer');
    // }, []);
    useEffect(() => {
        if (order?.currentStage) {
            const roles = {
                "Старт": "Дизайн",
                "Дизайн": "Печать",
                "Печать": "Постпечать",
                "Постпечать": "Установка",
                "Установка": "Финиш",
                "Финиш": "Старт"
            };
            console.log('order?.currentStage order.currentStage: ', order.currentStage);
            console.log('order?.currentStage roles[order.currentStage]: ', roles[order.currentStage]);
            setCurrentStage(roles[order.currentStage]  || '');
            setOrderCurrentStage(order?.currentStage);
        }
    }, [order?.currentStage]); 
    const { handleUpdateOrderProperty, handleUpdateOrderProperties, handleUpdateOrderCurrentStageProperty } = useOrderHelpers();

    const isButtonEnabled = () => {
        const stageExists = !!order.stages.find((stage) => stage.stageName === order.currentStage);
        console.log('isButtonEnabled stageExists:', stageExists);
        return stageExists;
    };
    const handleStarted = async (e) => {
        e.preventDefault();
        try {
            console.log('user: ', user,'temp currentStage: ', currentStage,'order.currentStage: ', order.currentStage)
            if(order.currentStage === 'Дизайн'){
                await handleUpdateOrderProperty(_id, 'info.paymentForDesign', parseFloat(paymentForDesign))
            }else if(order.currentStage === 'Печать'){
                await handleUpdateOrderProperty(_id, 'info.paymentForPrinter', parseFloat(paymentForPrinter))
            }else if(order.currentStage === 'Постпечать'){
                // await handleUpdateOrderProperty(_id, 'info.paymentForPostprinter', parseFloat(paymentForPostprinter))
                await handleUpdateOrderProperties(_id,{'info.frames':frames,'info.leuvers':leuvers});
            }else if (order.currentStage === 'Установка') {
                await handleUpdateOrderProperty(_id, 'info.paymentForInstallation', parseFloat(paymentForInstallation));
            }

            await handleUpdateOrderCurrentStageProperty(order._id, 'currentStage', order.currentStage, user.id, order)
            window.location.reload();
        } catch (error) {
            console.error('Error updating order:', error);
        } 
    };
    const handleCompleted = async (e)=>{
        e.preventDefault();
        try{
            // console.log('user: ',user,'temp currentStage: ',currentStage,'order.currentStage: ',order.currentStage)
            await Promise.all([
                handleUpdateOrderCurrentStageProperty(order._id, 'currentStage', order.currentStage, user.id, order),
                handleUpdateOrderProperty(_id, 'currentStage', currentStage)
            ]);

            // console.log(`handleCompleted Navigating to /orderStage/${orderCurrentStage}`);
            navigate(`/orderStage/${orderCurrentStage}`, { replace: true });
            // console.log('handleCompleted Navigation completed.');
        } catch (error) {
            console.error('Error completing stage:', error);
        }
    };
    if (isLoading) return <p>Жүктөлүп жатат...</p>;
    if (error) return <p>Ката чыкты</p>;
    return (
        <div className="table">        
        <>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1"></div>
            <div className="span-1 label">клиент</div>
            <div className="span-1 label value">{order.customer}</div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1 label">аталышы</div>
            <div className="span-1 label value">{order.title}</div>
            <div className="span-3"></div>
        
            <div className="span-1"></div>
            <div className="span-1 label">телефон</div>
            <div className="span-1 label value">
                <a
                    href={`https://wa.me/${order.info.whatsapp}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-link"
                >
                    {order.info.whatsapp}
                </a>
            </div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1 label">материал</div>
            <div className="span-1 label value">
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    {order.info.materials.map((material) => (
                        <span key={material._id}>
                            {material.title}
                        </span>
                    ))}
                </span>
            </div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1"></div>
            <div className="span-1 label">размер</div>
            <div className="span-1"></div>
            <div className="span-1 label">м2</div>
            <div className="span-2 label">Маалымат</div>

            <div className="span-1"></div>
            <div className="span-1 label value">{order.info.dimensions.height} cm</div>
            <div className="span-1 label value">{order.info.dimensions.width} cm</div>
            <div className="span-1 label value">{order.info.dimensions.size}</div>
            <div className="span-2 label value">{order.details}</div>

            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1 label">Стадия</div>
            <div className="span-1 label value">{order.currentStage}</div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            </>

            
                    
                    {order.currentStage === 'Постпечать' ? (
                        <div className="span-1 label">Рейка</div>
                    ) : (
                        <div className="span-1 label">{order.currentStage}</div>
                    )}
                    <div className="span-1 label green-value">
                        <div className="payment-container">
                        { order.currentStage === 'Дизайн' && (
                            <>
                                <input name="paymentForDesign" 
                                value={paymentForDesign} 
                                onChange={(e)=>setPaymentForDesign(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        )} 
                        {order.currentStage === 'Печать' && (
                            <>
                                <input name="paymentForPrinter" 
                                value={paymentForPrinter} 
                                onChange={(e)=>setPaymentForPrinter(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        ) }
                        {order.currentStage === 'Постпечать' && (
                            <>
                                <input name="frames" 
                                value={frames} 
                                onChange={(e)=>setFrames(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        )}
                        {order.currentStage === 'Установка' && (
                            <>
                                <input name="paymentForInstallation" 
                                value={paymentForInstallation} 
                                onChange={(e)=>setPaymentForInstallation(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        )}
                        </div>
                    </div>
                    <div className="span-1"></div>
                    <div className="span-2 green-box">
                        <button 
                            onClick={handleStarted} 
                            className={`whatsapp-link `}
                            disabled={isButtonEnabled()}
                            type="button"
                        >
                            Кабыл алдым
                        </button>
                    </div>
                    <div className="span-1"></div>



                    
                    {order.currentStage === 'Постпечать' ? (
                        <>
                            <div className="span-1 label">Люверси</div>
                            <div className="span-1 label green-value">
                                <div className="payment-container">
                                    <input
                                        name="leuvers"
                                        value={leuvers}
                                        onChange={(e) => setLeuvers(e.target.value)}
                                        placeholder="500"
                                    />
                                    <div>сом</div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                        <div className="span-1"></div>
                        <div className="span-1"></div>
                        </>
                    )}
                    <div className="span-1"></div>
                    <div className="span-2 green-box">
                        <button onClick={handleCompleted} 
                            className={`whatsapp-link `}
                            disabled={!isButtonEnabled()}
                            type="button"
                        >
                            Аткарылды
                        </button>
                    </div>
                    <div className="span-1"></div>


                   
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                

                    {/* {order.currentStage === 'Постпечать' ? (
                        <>
                            <div className="span-1 label">Установка</div>
                            <div className="span-1 label green-value">
                                <div className="payment-container">
                                    <input
                                        name="paymentForDesign"
                                        value={paymentForDesign}
                                        onChange={(e) => setPaymentForDesign(e.target.value)}
                                        placeholder="500"
                                    />
                                    <div>сом</div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                        <div className="span-1"></div>
                        <div className="span-1"></div>
                        </>
                    )}
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div> */}


            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
        </div>);
};

export default Designer;