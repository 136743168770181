import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetAllMaterialsQuery } from '../../redux/list/material/materialApi';
import { useGetAllCustomersQuery } from '../../redux/list/customer/customerApi';
import './../css/createOrder.css';
import { Link } from 'react-router-dom';

const CreateOrder = () => {
    const { data: materials = [] } = useGetAllMaterialsQuery();
    const { data: customers = [] } = useGetAllCustomersQuery();
    const { user } = useSelector((state) => state.auth);
    console.log('user.id: ',user.id)
    const navigate = useNavigate();
    // const [selectedMaterial, setSelectedMaterial] = useState('');
    // const [isLoadinggg, setIsLoadinggg] = useState(false);
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [whatsapp, setWhatsapp] = useState(0);
    const [priority, setPriority] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [newCustomer, setNewCustomer] = useState('');
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');

    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');
    const [size, setSize] = useState(0);
    const [rate, setRate] = useState(0);    
    const [paymentForDesign, setPaymentForDesign] = useState(0);
    const [paymentForPrinter, setPaymentForPrinter] = useState(0);
    const [paymentForPostprinter,setPaymentForPostprinter] = useState(0);
    
    const [quantity, setQuantity] = useState(1);
    const [frames, setFrames] = useState(0);
    const [leuvers, setLeuvers] = useState(0);
    const [paymentForInstallation, setPaymentForInstallation] = useState(0);
    
    const [price, setPrice] = useState(0);
    const [paid, setPaid] = useState(0);
    const [debt, setDebt] = useState(0);

    const [message, setMessage] = useState('');
    // Function to calculate the size
    const calculateSize = (h, w) => {
        const heightInMeters = h / 100;
        const widthInMeters = w / 100;
        return (heightInMeters * widthInMeters).toFixed(2);
    };

    // Function to update price
    const calculatePrice = () => {
        const calculatedPrice =
            // parseFloat(paymentForPrinter || 0) +
            parseFloat(paymentForDesign || 0) +
            (size * rate +            
            parseFloat(frames || 0) +
            parseFloat(leuvers || 0) +
            parseFloat(paymentForInstallation || 0)) *
            quantity;
        setPrice(calculatedPrice.toFixed(2));
    };

    const calculateAfterPrint = () => {
        const calculatedAfterPrint =
            (parseFloat(frames || 0) +
            parseFloat(leuvers || 0) +
            parseFloat(paymentForInstallation || 0)) *
            quantity;
        setPaymentForPostprinter(calculatedAfterPrint.toFixed(2));
    };

    // Function to calculate paid and debt
    const calculatePayment = () => {
        const calculatedPaid = parseFloat(paymentAmount || 0);
        const calculatedDebt = parseFloat(price || 0) - calculatedPaid;
        setPaid(calculatedPaid.toFixed(2));
        setDebt(calculatedDebt.toFixed(2));
    };

    // Update size when height or width changes
    const handleHeightChange = (e) => {
        // console.log('selectedMaterials: ',selectedMaterials)
        const newHeight = e.target.value;
        setHeight(newHeight);
        if (newHeight && width) {
            const newSize = calculateSize(newHeight, width);
            setSize(newSize);
        } else {
            setSize(0);
        }
    };

    const handleWidthChange = (e) => {
        const newWidth = e.target.value;
        setWidth(newWidth);
        if (height && newWidth) {
            const newSize = calculateSize(height, newWidth);
            setSize(newSize);
        } else {
            setSize(0);
        }
    };

    useEffect(() => {
        calculatePrice();
    }, [size, rate, paymentForDesign, paymentForPrinter, frames, leuvers, paymentForInstallation, quantity]);

    useEffect(() => {
        calculateAfterPrint();
    }, [frames, leuvers, paymentForInstallation, quantity]);

    // Update paid and debt whenever paymentAmount or price change
    useEffect(() => {
        calculatePayment();
    }, [paymentAmount, price]);
    const handleMaterialChange = (e) => {
        // const selectedOptions = Array.from(e.target.selectedOptions, (option) => {
        //     const material = materials.find((mat) => mat._id === option.value);
        //     return material ? { id: material._id, title: material.title } : null;
        // }).filter((item) => item !== null); // Remove null values
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setSelectedMaterials(selectedOptions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedPriority = priority || "Обычный";
        const now = new Date().toISOString(); // Current time in ISO format
        const completedAt = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(); // 3 days from now
        const currentStage = 'Дизайн';
        const orderData = {
          details,
          title,
          customer: selectedCustomer ? selectedCustomer : newCustomer,
          status: true,
          currentStage,
          stages: [
          ],
          info:{
            materials: selectedMaterials,
            payments: { amount: paymentAmount, method: paymentMethod },
            priority:selectedPriority,
            dimensions:{height,width,size},
            whatsapp,
            paymentForDesign,
            paymentForPrinter,
            paymentForPostprinter,
            rate,
            quantity,
            frames,
            leuvers,
            paymentForInstallation,
            price
          },
          createdBy: user.id
        };
    
        console.log('orderData: ',orderData);
        try {
          const response = await fetch('https://api.ilim-ordo.kg/api/order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderData),
          });
    
          if (response.ok) {
            setMessage('Order created successfully!');
            navigate('/orders', { replace: true }); // Redirect when user is logged in, with replace option
          } else {
            const errorData = await response.json();
            setMessage(`Error: ${errorData.error || 'Something went wrong'}`);
          }
        } catch (err) {
          setMessage(`Error: ${err.message}`);
        }
    };
    
    return (
        <div className="container">
            <div className="form-container">
                <h2 className="mb30">Заказ киргизүү формасы</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-grid" style={{marginBottom:'30px'}}>

                        

                        <div className="form-group mb30">
                            <label htmlFor="title">Аталышы</label>
                            <input
                                type="text"
                                id="title"
                                name="title"                            
                                value={title} // Bind the state to the input value
                                onChange={(e)=>{setTitle(e.target.value);}} // Handle changes
                            />
                        </div>

                        <div className="form-group mb30">
                            <label htmlFor="details">Маалымат</label>
                            <input type="text" id="details" name="details" 
                                value={details} // Bind the state to the input value
                                onChange={(e)=>{setDetails(e.target.value);}} // Handle changes
                                ></input>
                        </div>

                        <div className="form-group mb30">
                            <label htmlFor="quantity">Саны</label>
                            <input type="number" id="quantity" name="quantity" 
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}></input>
                        </div>



                        <div className="form-group mb30">
                            <label htmlFor="whatsapp">Whatsapp номери</label>
                            <input type="number" id="whatsapp" name="whatsapp" 
                                placeholder="0555111333"
                                value={whatsapp} // Bind the state to the input value
                                onChange={(e)=>{setWhatsapp(e.target.value);}} ></input>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="priority">Приоритет</label>
                            <select id="priority" name="priority" 
                                    value={priority} 
                                    onChange={(e)=>setPriority(e.target.value)}>
                                <option key="0" value="">Тандаңыз</option>
                                <option value="Обычный">Обычный</option>
                                <option value="Срочный">Срочный</option>
                            </select>
                        </div>

                        <div className="form-group mb30"></div>

                        <div className="form-group mb30">
                            <label htmlFor="customer">Клиент</label>
                            <select 
                            id="customer"
                            value={selectedCustomer} 
                            onChange={(e)=>setSelectedCustomer(e.target.value)}                          
                            >
                                <option key="0" value="">Тандаңыз</option>
                                {customers.map(customer => (
                                    <option key={customer._id} value={customer.title}>
                                        {customer.title}
                                    </option>
                                ))}
                            </select>
                            <div style={{ marginTop: '20px' }} >
                                <label htmlFor="newCustomer">Жаңы клиент болсо</label>
                                <input type="text" id="newCustomer" name="newCustomer" 
                                    value={newCustomer} // Bind the state to the input value
                                    onChange={(e)=>{setNewCustomer(e.target.value);}} // Handle changes
                                ></input>
                            </div>
                        </div>

                        <div className="form-group  mb30">
                            <label htmlFor="paymentAmount">Төлөм</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="paymentAmount"
                                    name="paymentAmount"                                    
                                    value={paymentAmount}
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                            <div style={{ marginTop: '20px' }} >
                                <label htmlFor="paymentMethod">Төлөө жолу</label>
                                <select
                                    id="paymentMethod" 
                                    name="paymentMethod"
                                    value={paymentMethod} 
                                    onChange={(e)=>setPaymentMethod(e.target.value)}     
                                >
                                    <option key="0" value="">Тандаңыз</option>
                                    <option value="акчалай">акчалай</option>
                                    <option value="мбанк">мбанк</option>
                                    <option value="перечис.">перечис.</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group mb30">
                            <label htmlFor="materials">Материалдар</label>
                            <select
                                id="materials"
                                value={selectedMaterials}//value={selectedMaterials.map((mat) => mat.id)} // Use only the IDs for `value`
                                onChange={handleMaterialChange}
                                multiple
                                className="select-margin"
                            >
                                {materials.map((material) => (
                                    <option key={material._id} value={material._id}>
                                        {material.title}
                                    </option>
                                ))}
                            </select>
                            {/* <div>
                                <h4 style={{fontSize: '12px'}}>Тандалган материалдар:</h4>
                                <ul id="selectedMaterials">
                                    {selectedMaterials.map((mat) => (
                                        <li key={mat.id} className="list-item" style={{fontSize: '10px'}}>
                                            {mat.title} 
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    {/* </div>

                    <div className="form-grid-four"> */}
                        <div className="row-wrapper mb30">
                            <div className="form-group">
                                <label htmlFor="height">Узуну</label>
                                <div className="row-container">
                                    <input
                                        type="number"
                                        id="height"
                                        name="height"                                        
                                        value={height}
                                        onChange={handleHeightChange}
                                    />
                                    <span>см</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="width">Туурасы</label>
                                <div className="row-container">
                                    <input
                                        type="number"
                                        id="width"
                                        name="width"                                        
                                        value={width}
                                        onChange={handleWidthChange}
                                    />
                                    <span>см</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="size">Размер</label>
                                <div className="row-container">
                                    <input
                                        type="number"
                                        id="size"
                                        disabled
                                        value={size}
                                        style={{ backgroundColor: 'lightyellow' }} 
                                    />
                                    <div className="equation">
                                        <span>
                                            м<span className="superscript">2</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb30">
                            <label htmlFor="rate">Ставка</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="rate"
                                    name="rate"
                                    value={rate}
                                    onChange={(e) => setRate(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="paymentForDesign">Дизайнердин акысы</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="paymentForDesign"
                                    name="paymentForDesign"
                                    value={paymentForDesign}
                                    onChange={(e) => setPaymentForDesign(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                        </div>

                    </div>
                    <div className="form-grid-four">

                        <div className="form-group mb30">
                            <label htmlFor="frames">Рейка</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="frames"
                                    name="frames"
                                    value={frames}
                                    onChange={(e) => setFrames(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="leuvers">Люверси</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="leuvers"
                                    name="leuvers"
                                    value={leuvers}
                                    onChange={(e) => setLeuvers(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="paymentForInstallation">Установка</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="paymentForInstallation"
                                    name="paymentForInstallation"
                                    value={paymentForInstallation}
                                    onChange={(e) => setPaymentForInstallation(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="paymentForPostprinter">Печаттан кийин</label>
                            <div className="row-container">
                                <input
                                    type="text"
                                    id="paymentForPostprinter"
                                    disabled
                                    value={paymentForPostprinter}
                                    style={{ backgroundColor: 'lightyellow' }} 
                                />
                                <span>сом</span>
                            </div>
                        </div>

                        <div className="form-group mb30">
                            <label htmlFor="price">Жалпы баасы</label>
                            <div className="row-container">
                                <input
                                    type="text"
                                    id="price"
                                    disabled
                                    value={price}
                                    style={{ backgroundColor: 'lightyellow' }} 
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="paid">Төлөндү</label>
                            <div className="row-container">
                                <input
                                    type="text"
                                    id="paid"
                                    disabled
                                    value={paid}
                                    style={{ backgroundColor: 'lightyellow' }} 
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="debt">Калдыгы</label>
                            <div className="row-container">
                                <input
                                    type="text"
                                    id="debt"
                                    disabled
                                    value={debt}
                                    style={{ backgroundColor: 'lightyellow' }} 
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="paymentForPrinter">Печатниктин акысы</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="paymentForPrinter"
                                    name="paymentForPrinter"
                                    value={paymentForPrinter}
                                    onChange={(e) => setPaymentForPrinter(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="button-container">
                            <button type="submit" className="button">
                                Заказды сактоо
                            </button>
                        </div>
                    </div>
                </form>
                {message && <p>{message}</p>}
            </div>
            {/* <Link to={'/designer/674d8b22e3b4332ea6446838'}>ok</Link> */}
        </div>
    );
};

export default CreateOrder;

