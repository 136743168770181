// SignIn.js
import React, { useState } from 'react';
import { useSigninMutation } from '../../redux/auth/authApi';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../redux/auth/authSlice';
import { useNavigate, Link } from 'react-router-dom';
import './auth.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signin, { isLoading }] = useSigninMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate hook
  const [error, setError] = useState(null); // To handle login errors
  const { user } = useSelector((state) => state.auth); // Get user from Redux state

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await signin({ email, password }).unwrap();
      console.log('userData in sign in submit', userData);
      dispatch(loginSuccess(userData)); // Dispatch after login success
      if (userData) {
        navigate('/ilimOrdo', { replace: true }); // Redirect when user is logged in, with replace option
      }
    } catch (err) {
      console.error('Failed to login: ', err);
      setError('Invalid email or password. Please try again.'); // Display error
    }
  };

  return (
        <>
          <div className="auth-container">
            <h2>Кириңиз </h2>
            <form className="auth-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-input"
                  placeholder="Email"
                
                />
              </div>
              <div className="form-group">
                <label>Пароль</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-input"
                  placeholder="Пароль"
                />
              </div>
              <button type="submit" className="submit-button" disabled={isLoading}>
                {isLoading ? 'Жүктөлүп жатат...' : 'Кириңиз'}
                
              </button>
            </form>
            {error && <p className="error-message">{error}</p>} {/* Show error message */}
            <p className="signup-link">
              Аккаунтуңуз жокпу? <Link to="/signup">Катталыңыз</Link>
            </p>
          </div>
        </>
  );
};

export default SignIn;

