import { useUpdateOrderMutation } from '../redux/order/orderApi';

export const useOrderHelpers = () => {
    const [updateOrder] = useUpdateOrderMutation();

    const handleUpdateOrderProperty = async (orderId, property, value) => {
        try {
            const updateData = {
                id: orderId,
                [property]: value,
            };
            await updateOrder(updateData);
        } catch (error) {
            console.error("Error updating order property:", error);
        }
    };

    const handleUpdateOrderProperties = async (orderId, updates) => {
        try {
            // Construct the update data with the order ID and the fields to update
            const updateData = {
                id: orderId,
                ...updates, // Spread the updates object to include all fields
            };
    
            await updateOrder(updateData);
        } catch (error) {
            console.error("Error updating order properties:", error);
        }
    };

    const handleUpdateOrderCurrentStageProperty = async (orderId, property, value, userId, order) => {
        try {
            console.log('value: ',value, 'order.stages: ',order?.stages)
            // Check if the stageName exists in the stages array
            const existingStage = order.stages?.find(stage => stage.stageName === value);
            console.log('existingStage: ',existingStage)
            // Construct the stage object
            const stageData = existingStage
                ? { id:existingStage.id, stageName: value, completedAt: new Date().toISOString() } // Update completedAt for existing stage
                : {
                      stageName: value,
                      assignedUsers: [userId],
                      startedAt: new Date().toISOString(),
                  }; // Add a new stage if it doesn't exist
    
            console.log('stageData: ',stageData)

            // Send updated data to the backend
            const updateData = {
                id: orderId,
                [property]: value,
                stages: [stageData], // Only send the relevant stage
            };
            console.log('updateData: ',updateData)
            await updateOrder(updateData); // Call your API mutation
            console.log("Order updated:", updateData);
        } catch (error) {
            console.error("Error updating current stage:", error);
        }
    };
    

    return { handleUpdateOrderProperty, handleUpdateOrderProperties, handleUpdateOrderCurrentStageProperty };
};
